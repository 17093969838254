import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import TDesign from 'tdesign-vue-next';
import { createPinia } from 'pinia';
import loader from '@monaco-editor/loader';

// 引入 TDesign 的样式
import 'tdesign-vue-next/dist/reset.css';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(TDesign);

app.mount('#app');
loader.config({
    paths: {
        vs: '/vs',
    },
});

