import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center","gap":"10px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "editor-container" }
const _hoisted_5 = { class: "bordered-div" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "action-buttons" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "scrollable-container" }
const _hoisted_11 = { class: "test-cases-container" }
const _hoisted_12 = { class: "test-case-header" }
const _hoisted_13 = { class: "input-section" }
const _hoisted_14 = { class: "expected-section" }
const _hoisted_15 = {
  key: 0,
  class: "actual-section"
}
const _hoisted_16 = { class: "output-section" }
const _hoisted_17 = {
  key: 1,
  class: "output-container"
}
const _hoisted_18 = { class: "output-content" }
const _hoisted_19 = {
  key: 0,
  class: "save-status"
}

import {nextTick, onMounted, ref, toRaw, watch, onUnmounted, onBeforeUnmount} from "vue";
import {PlayCircleStrokeIcon, SettingIcon, AppIcon, ShareIcon, CodeIcon} from 'tdesign-icons-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import loader from '@monaco-editor/loader';
// import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import {useEditorStore} from '@/store/editor';
import RunDetails from "@/components/RunDetails.vue";
import EditorSettings from '@/components/EditorSettings.vue';
import {useEditorSettingsStore} from '@/store/editorSettings';
import { MessagePlugin } from 'tdesign-vue-next';
import AIExplainer from '@/components/AIExplainer.vue';
import ShareCode from '@/components/ShareCode.vue';
import TemplatesManager from '@/components/TemplatesManager.vue';
import SnippetsManager from '@/components/SnippetsManager.vue';
import { useSnippetsStore } from '@/store/snippets';
import { useUserStore } from '@/store/user';

// 配置 Monaco 编辑器的本地化
interface TestCase {
  input: string;
  expected?: string;
  output?: string;
  status?: string;
}

enum Status {
  Accepted = 'Accepted', // 正常情况
  MemoryLimitExceeded = 'Memory Limit Exceeded', // 内存超限
  TimeLimitExceeded = 'Time Limit Exceeded', // 时间超限
  OutputLimitExceeded = 'Output Limit Exceeded', // 输出超限
  FileError = 'File Error', // 文件错误
  NonzeroExitStatus = 'Nonzero Exit Status', // 非 0 退出值
  Signalled = 'Signalled', // 进程被信号终止
  InternalError = 'Internal Error', // 内部错误
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

loader.config({
  'vs/nls': {
    availableLanguages: {
      '*': 'zh-cn'
    }
  }
});

const editorStore = useEditorStore();
const settingsStore = useEditorSettingsStore();
const snippetsStore = useSnippetsStore();
const userStore = useUserStore();

// 判题结果相关状态
const isSuccessful = ref(false);
const isError = ref(false);
const output = ref('');
const isLoading = ref(false);
const runInfo = ref<any[]>([]);
const drawerVisible = ref(false);

// Monaco 编辑器和其他必要的变量
const codeEditorRef = ref();
const codeEditor = ref();
const value = ref(editorStore.code || ''); // 初始化为 store 值
const value3 = ref(''); // 用于输入测试用例的变量

// 填充示例代码
const fillValue = () => {
  if (!codeEditor.value) {
    return;
  }
  // 改变编辑器中的内容
  toRaw(codeEditor.value).setValue(`#include<iostream>
using namespace std;
int main() {
    cout<<"Hello world!"<<endl;
    return 0;
}`);
};

const updateDrawerVisible = (newVisible: boolean) => {
  drawerVisible.value = newVisible;
};

const openRunDetails = () => {
  drawerVisible.value = true;
}

// 主题相关的状态
const currentTheme = ref('vs');
const themes = [
  { value: 'vs', label: '浅色主题' },
  { value: 'vs-dark', label: '深色主题' },
  { value: 'hc-black', label: '高对比度' }
];

// 语言相关的态
const languages = [
  { value: 'cpp', label: 'C++' },
  { value: 'c', label: 'C' },
  { value: 'java', label: 'Java' },
  { value: 'python', label: 'Python' },
  { value: 'javascript', label: 'JavaScript' },
  { value: 'typescript', label: 'TypeScript' },
  { value: 'go', label: 'Go' },
  { value: 'rust', label: 'Rust' },
  { value: 'csharp', label: 'C#' },
  { value: 'php', label: 'PHP' },
  { value: 'ruby', label: 'Ruby' },
  { value: 'swift', label: 'Swift' },
  { value: 'kotlin', label: 'Kotlin' },
  { value: 'scala', label: 'Scala' },
  { value: 'sql', label: 'SQL' },
];

const currentLanguage = ref('cpp');

// 监听主题变化
watch(currentTheme, async (newTheme) => {
  if (codeEditor.value) {
    const monacoInstance = await loader.init();
    monacoInstance.editor.setTheme(newTheme);
  }
});

// 配置 Monaco Editor 的 workers
window.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'css' || label === 'scss' || label === 'less') {
      return new cssWorker();
    }
    if (label === 'html' || label === 'handlebars' || label === 'razor') {
      return new htmlWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    return new editorWorker();
  }
};

const completionProviders = ref<any[]>([]);

// 修改注册代码片段补全提供者的代码
const registerSnippetsCompletionProvider = async () => {
  // 清理已存在的补全提供者
  disposeCompletionProviders();
  
  const monacoInstance = await loader.init();
  
  // 为每种语言注册补全提供者
  const languages = ['cpp', 'javascript', 'typescript', 'python', 'java'];
  
  languages.forEach(language => {
    const provider = monacoInstance.languages.registerCompletionItemProvider(language, {
      provideCompletionItems: (model, position) => {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };

        return {
          suggestions: snippetsStore.snippets.map(snippet => ({
            label: snippet.trigger,
            kind: monacoInstance.languages.CompletionItemKind.Snippet,
            documentation: snippet.description,
            insertText: snippet.content,
            range: range,
            detail: '自定义代码片段'
          }))
        };
      }
    });
    
    completionProviders.value.push(provider);
  });
};

// 添加清理补全提供者的方法
const disposeCompletionProviders = () => {
  completionProviders.value.forEach(provider => provider?.dispose());
  completionProviders.value = [];
};

// 添加 resize 事件处理函数的引用
const handleEditorResize = () => {
  if (codeEditor.value) {
    const editor = toRaw(codeEditor.value);
    editor.layout();
  }
};

// 修改 onMounted 中的事件监听部分
onMounted(async () => {
  if (!codeEditorRef.value) return;

  try {
    const monacoInstance = await loader.init();
    
    // 设置初始主题
    monacoInstance.editor.setTheme(currentTheme.value);
    
    // 确保已经获取到代码片段
    if (userStore.isLoggedIn && snippetsStore.snippets.length === 0) {
      await snippetsStore.initialize();
    }
    
    // 创建编辑器实例
    codeEditor.value = monacoInstance.editor.create(codeEditorRef.value, {
      value: value.value,
      language: currentLanguage.value,
      automaticLayout: true,
      fontFamily: "Fira Code",
      fontSize: settingsStore.settings.fontSize,
      tabSize: settingsStore.settings.tabSize,
      wordWrap: settingsStore.settings.wordWrap ? 'on' : 'off',
      lineNumbers: settingsStore.settings.lineNumbers ? 'on' : 'off',
      minimap: {
        enabled: settingsStore.settings.minimap
      },
      fontLigatures: settingsStore.settings.fontLigatures,
      theme: currentTheme.value,
      suggestSelection: 'first',
      wordBasedSuggestions: 'matchingDocuments',
      quickSuggestions: true,
    });

    // 注册代码片段补全提供者
    await registerSnippetsCompletionProvider();

    // 添加快捷键打开代码片段管理器
    codeEditor.value.addAction({
      id: 'show-snippets-manager',
      label: '管理代码片段',
      keybindings: [
        monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.KeyJ
      ],
      run: () => {
        showSnippetsManager.value = true;
      }
    });

    // 添加窗口大小变化的监听
    window.addEventListener('resize', handleEditorResize);
    
  } catch (error) {
    console.error('Monaco editor initialization failed:', error);
  }

  // 初始化代码高亮
  hljs.highlightAll();

  // 设置自动保存
  setupAutoSave();
});

// 主题切换方法
const changeTheme = (newTheme: string) => {
  currentTheme.value = newTheme;
};

// 语言切换方法
const changeLanguage = async (newLang: string) => {
  if (!codeEditor.value) return;
  
  // 更新编辑器语言
  const editor = toRaw(codeEditor.value);
  const model = editor.getModel();
  
  if (model) {
    monaco.editor.setModelLanguage(model, newLang);
  }

  if (newLang !== 'cpp') {
    output.value = '非C++语言目前仅支持代码高亮，暂不支持运行，持续开发中...';
    isSuccessful.value = false;
    isError.value = true;
  }
};

// 题逻
const testCases = ref<TestCase[]>([{ input: '', expected: '' }]);

const addTestCase = () => {
  testCases.value.push({ input: '', expected: '' });
};

const removeTestCase = (index: number) => {
  testCases.value.splice(index, 1);
};

const runCode = async () => {
  if (!codeEditor.value) {
    console.error("Code editor not initialized.");
    return;
  }

  // 检查当前语言
  if (currentLanguage.value !== 'cpp') {
    MessagePlugin.warning({
      content: '非C++语言目前仅支持码高亮，暂不支持运行，持续开发中...',
      duration: 3000,
      closeBtn: true,
    });
    return;
  }

  isLoading.value = true;
  const code = toRaw(codeEditor.value).getValue();
  
  try {
    const results = await Promise.all(
      testCases.value.map(testCase => 
        submitToJudgeSystem(code, testCase.input)
      )
    );
    
    runInfo.value = results;
    displayResults(results);
  } catch (error) {
    console.error("提交失败:", error);
    displayError(error);
  } finally {
    isLoading.value = false;
  }
};

// 向判题系统提交请求
const submitToJudgeSystem = async (code: string, input: string) => {
  const response = await fetch('https://panti.emoera.top/run.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code, // Monaco 编辑器中的代码
      input, // 用户输入的测试用例
    }),
  });

  if (!response.ok) {
    throw new Error('提交失败');
  }

  const result = await response.json();
  return result;
};

// 根据判题结果更新 UI 状态
const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'Accepted': '运行成功',
    'Memory Limit Exceeded': '内存超限',
    'Time Limit Exceeded': '时间限',
    'Output Limit Exceeded': '输出超限',
    'File Error': '文件错误',
    'NonzeroExitStatus': '运行异常',
    'Signalled': '进程被终止',
    'Internal Error': '内部错误'
  };
  return statusMap[status] || status;
};

const displayResults = (results: any[]) => {
  // 只要收到服务器返回的结果就显示运行成功
  isSuccessful.value = true;
  isError.value = false;
  
  output.value = results.map((result, index) => {
    const testCase = testCases.value[index];
    let outputText = `测试用例 #${index + 1}:\n`;
    
    if (result.status === Status.Accepted) {
      const actualOutput = (result.files?.stdout || '').trim();
      outputText += `状态: 通过\n`;
      
      // 如果有预期输出，进行对比
      if (testCase.expected && testCase.expected.trim()) {
        const expectedOutput = testCase.expected.trim();
        const isMatch = actualOutput === expectedOutput;
        
        outputText += isMatch 
          ? `结果: 答案正确 ✓\n` 
          : `结果: 答案错误 ✗\n`;
        
        if (!isMatch) {
          outputText += `预期输出:\n${expectedOutput}\n`;
          outputText += `实际输出:\n${actualOutput}\n`;
          outputText += `差异说明:\n`;
          
          if (actualOutput.length !== expectedOutput.length) {
            outputText += `- 输出长度不一致（预期: ${expectedOutput.length}, 实际: ${actualOutput.length}）\n`;
          }
          
          // 检查是否只是空白字符的差异
          const normalizedExpected = expectedOutput.replace(/\s+/g, ' ');
          const normalizedActual = actualOutput.replace(/\s+/g, ' ');
          if (normalizedExpected === normalizedActual) {
            outputText += `- 输出内容相同，但空白字符不一致\n`;
          }
          
          // 检查是否有多余或缺少的换行
          const expectedLines = expectedOutput.split('\n');
          const actualLines = actualOutput.split('\n');
          if (expectedLines.length !== actualLines.length) {
            outputText += `- 换行数量不一致（预期: ${expectedLines.length}, 实际: ${actualLines.length}）\n`;
          }
        }
      } else {
        outputText += `输出:\n${actualOutput}\n`;
      }
    } else {
      outputText += `状态: ${getStatusText(result.status)}\n`;
      if (result.files?.stderr) {
        outputText += `错误信息:\n${result.files.stderr}\n`;
      }
    }
    return outputText;
  }).join('\n---\n');
};

const displayError = (error: any) => {
  isError.value = true;
  isSuccessful.value = false;
  output.value = `请求失败: ${error.message || '未知错误'}`;
};

// 添加 isOutputMatch 函数
const isOutputMatch = (result: any, index: number) => {
  const testCase = testCases.value[index];
  if (!testCase?.expected) return true;
  const expectedOutput = testCase.expected.trim();
  const actualOutput = result.files?.stdout?.trim() || '';
  return expectedOutput === actualOutput;
};

const getAlertTheme = () => {
  if (!runInfo.value.length) return 'success';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return 'success';
  if (passedCases > 0) return 'warning';
  return 'error';
};

const getAlertMessage = () => {
  if (!runInfo.value.length) return '';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return '全部通过';
  if (passedCases > 0) return `部分通过（${passedCases}/${totalCases}）`;
  return '样例未通过';
};

// 添加设置相关的状态
const settingsVisible = ref(false);

// 添加以下方法
const openSettings = () => {
  settingsVisible.value = true;
};

const updateSettingsVisible = (newVisible: boolean) => {
  settingsVisible.value = newVisible;
};

// 添加 AI 解释相关的状态
const aiExplainerVisible = ref(false);

// 添加获取当前代码的方法
const getCurrentCode = () => {
  if (!codeEditor.value) return '';
  return toRaw(codeEditor.value).getValue();
};

// 添加打开 AI 解释器的方法
const openAIExplainer = () => {
  aiExplainerVisible.value = true;
};

// 添加更新 AI 解释器可见性的方法
const updateAIExplainerVisible = (newVisible: boolean) => {
  aiExplainerVisible.value = newVisible;
};

// 添加新的状态
const leftFlex = ref(7);
const rightFlex = ref(3);
const isResizing = ref(false);
const startX = ref(0);
const startLeftFlex = ref(0);

// 添加拖动相关方法
const startResize = (e: MouseEvent | TouchEvent) => {
  isResizing.value = true;
  startX.value = 'touches' in e ? e.touches[0].clientX : e.clientX;
  startLeftFlex.value = leftFlex.value;
  
  document.addEventListener('mousemove', handleResize);
  document.addEventListener('touchmove', handleResize);
  document.addEventListener('mouseup', stopResize);
  document.addEventListener('touchend', stopResize);
  
  // 添加禁止选择类
  document.body.classList.add('no-select');
};

const handleResize = (e: MouseEvent | TouchEvent) => {
  if (!isResizing.value) return;
  
  requestAnimationFrame(() => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const containerWidth = document.querySelector('.editor-container')?.clientWidth || 1000;
    
    const movementRatio = (clientX - startX.value) / containerWidth;
    const newLeftFlex = Math.max(2, Math.min(8, startLeftFlex.value + movementRatio * 10));
    
    leftFlex.value = newLeftFlex;
    rightFlex.value = 10 - newLeftFlex;
    
    // 强制触发 Monaco Editor 重新布局
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
      
      // 添加一个小延时来确保布局更新
      setTimeout(() => {
        editor.layout();
      }, 10);
    }
  });
};

const stopResize = () => {
  isResizing.value = false;
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  
  document.body.classList.remove('no-select');
  
  // 最后一次布局更新
  if (codeEditor.value) {
    toRaw(codeEditor.value).layout();
  }
};

// 修改 onBeforeUnmount，确保正确清理所有资源
onBeforeUnmount(() => {
  // 清理补全提供者
  disposeCompletionProviders();
  
  // 清理编辑器实例
  if (codeEditor.value) {
    toRaw(codeEditor.value).dispose();
    codeEditor.value = null;
  }
  
  // 移除窗口大小变化的监听器
  window.removeEventListener('resize', handleEditorResize);
  
  // 清理自动保存定时器
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
    autoSaveInterval.value = null;
  }
});

// 修改 onUnmounted，移除拖动相关的事件监听
onUnmounted(() => {
  // 移除拖动相关的事件监听
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  
  // 确保移除 no-select 类
  document.body.classList.remove('no-select');
});

// 添加自动保存功能
const autoSaveInterval = ref<number | null>(null);

// 在组件卸载前清除定时器
onBeforeUnmount(() => {
  // 清理补全提供者
  disposeCompletionProviders();
  
  // 清理编辑器实例
  if (codeEditor.value) {
    codeEditor.value.dispose();
  }
  
  // 移除窗口大小变化的监听器
  window.removeEventListener('resize', () => {
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
    }
  });
  
  // 清理自动保存定时器
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
  }
});

// 在编辑器初始化后设置自动保存
const handleEditorMounted = (editor: any) => {
  // ... 现有的编辑器初始化代码 ...
  
  // 设置自动保存
  setupAutoSave();
};

const showSaveStatus = ref(false);

const showSavedStatus = () => {
  showSaveStatus.value = true;
  setTimeout(() => {
    showSaveStatus.value = false;
  }, 2000);
};

// 修改自动保存函数
const setupAutoSave = () => {
  autoSaveInterval.value = window.setInterval(() => {
    if (codeEditor.value) {
      const currentCode = toRaw(codeEditor.value).getValue();
      const previousCode = editorStore.code;
      if (currentCode !== previousCode) {
        editorStore.setCode(currentCode);
        showSavedStatus();
      }
    }
  }, 1000);
};

// 添加更新编辑器内容的方法
const updateEditorContent = (newCode: string) => {
  if (codeEditor.value) {
    const editor = toRaw(codeEditor.value);
    editor.setValue(newCode);
    editorStore.setCode(newCode);
  }
};

const showSnippetsManager = ref(false);

// 监听 snippets 变化时重新注册补全提供者
watch(() => snippetsStore.snippets, async () => {
  await registerSnippetsCompletionProvider();
}, { deep: true });


return (_ctx: any,_cache: any) => {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_option = _resolveComponent("t-option")!
  const _component_t_select = _resolveComponent("t-select")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_alert = _resolveComponent("t-alert")!
  const _component_t_tag = _resolveComponent("t-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_t_button, {
        loading: isLoading.value,
        variant: "outline",
        theme: "success",
        class: "custom-button",
        onClick: runCode
      }, _createSlots({
        default: _withCtx(() => [
          (!isLoading.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "运行"))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, "运行中"))
        ]),
        _: 2
      }, [
        (!isLoading.value)
          ? {
              name: "icon",
              fn: _withCtx(() => [
                _createVNode(_unref(PlayCircleStrokeIcon))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["loading"]),
      _createVNode(_component_t_select, {
        modelValue: currentTheme.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentTheme).value = $event)),
        class: "theme-select",
        placeholder: "选择主题",
        onChange: changeTheme,
        style: {"width":"120px"}
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(themes, (theme) => {
            return _createVNode(_component_t_option, {
              key: theme.value,
              value: theme.value,
              label: theme.label
            }, null, 8, ["value", "label"])
          }), 64))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_t_select, {
        modelValue: currentLanguage.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentLanguage).value = $event)),
        class: "language-select",
        placeholder: "选择语言",
        onChange: changeLanguage,
        style: {"width":"120px","margin-left":"8px"}
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(languages, (lang) => {
            return _createVNode(_component_t_option, {
              key: lang.value,
              value: lang.value,
              label: lang.label
            }, null, 8, ["value", "label"])
          }), 64))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_t_button, {
        variant: "outline",
        theme: "default",
        onClick: openSettings
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(SettingIcon))
        ]),
        _: 1
      }),
      _createVNode(_component_t_button, {
        variant: "outline",
        theme: "default",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (showSnippetsManager.value = true))
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(CodeIcon))
        ]),
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("span", null, "代码片段", -1))
        ]),
        _: 1
      }),
      _createVNode(_component_t_button, {
        variant: "outline",
        theme: "primary",
        onClick: openAIExplainer
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(AppIcon))
        ]),
        default: _withCtx(() => [
          _cache[6] || (_cache[6] = _createElementVNode("span", null, "AI分析", -1))
        ]),
        _: 1
      }),
      _createVNode(ShareCode, {
        code: getCurrentCode(),
        language: currentLanguage.value
      }, null, 8, ["code", "language"]),
      _createVNode(TemplatesManager, {
        code: _unref(editorStore).code,
        "onUpdate:code": [
          _cache[3] || (_cache[3] = ($event: any) => ((_unref(editorStore).code) = $event)),
          updateEditorContent
        ],
        language: currentLanguage.value
      }, null, 8, ["code", "language"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "editor-section",
        style: _normalizeStyle({ flex: leftFlex.value })
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            id: "code-editor",
            ref_key: "codeEditorRef",
            ref: codeEditorRef,
            style: {"height":"80vh"}
          }, null, 512)
        ])
      ], 4),
      _createElementVNode("div", {
        class: "resizer",
        onMousedown: startResize,
        onTouchstart: startResize
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("div", { class: "resizer-line" }, null, -1)
      ]), 32),
      _createElementVNode("div", {
        class: "right-section",
        style: _normalizeStyle({ flex: rightFlex.value })
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_t_button, {
              variant: "outline",
              theme: "success",
              loading: isLoading.value,
              onClick: runCode
            }, _createSlots({
              default: _withCtx(() => [
                (!isLoading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "运行"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, "运行中"))
              ]),
              _: 2
            }, [
              (!isLoading.value)
                ? {
                    name: "icon",
                    fn: _withCtx(() => [
                      _createVNode(_unref(PlayCircleStrokeIcon))
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["loading"]),
            _createVNode(_component_t_button, {
              variant: "outline",
              theme: "primary",
              onClick: openRunDetails,
              style: {"flex":"1"}
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" 运行详情 ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(testCases.value, (testCase, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "test-case-item"
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("span", null, "测试用例 #" + _toDisplayString(index + 1), 1),
                    (testCases.value.length > 1)
                      ? (_openBlock(), _createBlock(_component_t_button, {
                          key: 0,
                          theme: "danger",
                          variant: "text",
                          size: "small",
                          onClick: ($event: any) => (removeTestCase(index))
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" 删除 ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["test-case-content", {
                  'success-case': runInfo.value?.[index]?.status === 'Accepted' && isOutputMatch(runInfo.value[index], index),
                  'error-case': runInfo.value?.[index]?.status === 'Accepted' && !isOutputMatch(runInfo.value[index], index)
                }])
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "input-label" }, "输入：", -1)),
                      _createVNode(_component_t_textarea, {
                        modelValue: testCase.input,
                        "onUpdate:modelValue": ($event: any) => ((testCase.input) = $event),
                        placeholder: `输入第 ${index + 1} 组测试用例`,
                        autosize: { minRows: 2, maxRows: 3 }
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "input-label" }, "预期输出：", -1)),
                      _createVNode(_component_t_textarea, {
                        modelValue: testCase.expected,
                        "onUpdate:modelValue": ($event: any) => ((testCase.expected) = $event),
                        placeholder: '输入预期结果（可选）',
                        autosize: { minRows: 2, maxRows: 3 }
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    (runInfo.value?.[index]?.status === 'Accepted' && 
                          testCase.expected && 
                          !isOutputMatch(runInfo.value[index], index))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "input-label error-text" }, "实际输出：", -1)),
                          _createVNode(_component_t_textarea, {
                            readonly: "",
                            value: runInfo.value[index].files?.stdout || '',
                            autosize: { minRows: 2, maxRows: 3 }
                          }, null, 8, ["value"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 2)
                ]))
              }), 128)),
              _createVNode(_component_t_button, {
                theme: "primary",
                variant: "text",
                size: "small",
                onClick: addTestCase,
                style: {"margin-top":"8px"}
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" + 添加测试用例 ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_16, [
              (runInfo.value.length > 0)
                ? (_openBlock(), _createBlock(_component_t_alert, {
                    key: 0,
                    theme: getAlertTheme(),
                    message: getAlertMessage()
                  }, null, 8, ["theme", "message"]))
                : _createCommentVNode("", true),
              (output.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[14] || (_cache[14] = _createElementVNode("h4", null, "输出：", -1)),
                    _createElementVNode("pre", _hoisted_18, _toDisplayString(output.value), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 4)
    ]),
    _createVNode(RunDetails, {
      runInfo: runInfo.value,
      visible: drawerVisible.value,
      testCases: testCases.value,
      "onUpdate:visible": updateDrawerVisible
    }, null, 8, ["runInfo", "visible", "testCases"]),
    _createVNode(EditorSettings, {
      visible: settingsVisible.value,
      editor: codeEditor.value,
      "onUpdate:visible": updateSettingsVisible
    }, null, 8, ["visible", "editor"]),
    _createVNode(AIExplainer, {
      visible: aiExplainerVisible.value,
      code: getCurrentCode(),
      "onUpdate:visible": updateAIExplainerVisible
    }, null, 8, ["visible", "code"]),
    (showSaveStatus.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_t_tag, {
            theme: "success",
            variant: "light"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("已自动保存")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(SnippetsManager, {
      visible: showSnippetsManager.value,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((showSnippetsManager).value = $event))
    }, null, 8, ["visible"])
  ], 64))
}
}

})