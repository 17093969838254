import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "username" }

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useSnippetsStore } from '@/store/snippets';
import { MessagePlugin } from 'tdesign-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();
const userStore = useUserStore();
const snippetsStore = useSnippetsStore();
const menu1Value = ref('item1');

// 在组件挂载时初始化代码片段
onMounted(async () => {
  if (userStore.isLoggedIn) {
    try {
      await snippetsStore.initialize();
    } catch (error) {
      console.error('初始化代码片段失败:', error);
    }
  }
});

// 跳转到账号中心
const goToAccountCenter = () => {
  window.open('https://account.emoera.com', '_blank');
};

// 处理退出登录
const handleLogout = async () => {
  try {
    const response = await fetch('/api/auth/logout', {
      method: 'POST',
      credentials: 'include'
    });
    
    const data = await response.json();
    if (data.success) {
      userStore.clearUserInfo();
      MessagePlugin.success('已退出登录');
      window.location.href = '/login';
    } else {
      throw new Error(data.message || '退出登录失败');
    }
  } catch (error) {
    MessagePlugin.error('退出登录失败，请重试');
    console.error('退出登录失败:', error);
  }
};

function openExternalLink() {
  window.open('https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2?p=BvAba1pUjsuoDHKNY65azz', '_blank');
}

function goToHome() {
  router.push('/');
}

const changeHandler = (active: string | number) => {
  console.log('change', active);
};

return (_ctx: any,_cache: any) => {
  const _component_t_icon = _resolveComponent("t-icon")!
  const _component_t_menu_item = _resolveComponent("t-menu-item")!
  const _component_t_avatar = _resolveComponent("t-avatar")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_dropdown_item = _resolveComponent("t-dropdown-item")!
  const _component_t_dropdown_menu = _resolveComponent("t-dropdown-menu")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_head_menu = _resolveComponent("t-head-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_t_head_menu, {
      modelValue: menu1Value.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((menu1Value).value = $event)),
      theme: "light",
      onChange: changeHandler
    }, {
      logo: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("img", {
          height: "49",
          src: "https://we.emoera.com/img/elogo.jpg",
          alt: "logo",
          style: {"border-radius":"30%"}
        }, null, -1)
      ])),
      operations: _withCtx(() => [
        (_unref(userStore).isLoggedIn)
          ? (_openBlock(), _createBlock(_component_t_dropdown, {
              key: 0,
              trigger: "click"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_t_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_dropdown_item, {
                      value: "profile",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/profile')))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_t_icon, { name: "user" })
                      ]),
                      default: _withCtx(() => [
                        _cache[7] || (_cache[7] = _createTextVNode(" 个人中心 "))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_t_dropdown_item, {
                      value: "settings",
                      onClick: goToAccountCenter
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_t_icon, { name: "setting" })
                      ]),
                      default: _withCtx(() => [
                        _cache[8] || (_cache[8] = _createTextVNode(" 账号设置 "))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_t_dropdown_item, {
                      value: "logout",
                      theme: "danger",
                      onClick: handleLogout
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_t_icon, { name: "poweroff" })
                      ]),
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createTextVNode(" 退出登录 "))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_t_button, {
                  variant: "text",
                  class: "user-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_avatar, {
                      size: "small",
                      image: _unref(userStore).userInfo?.avatar
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(userStore).userInfo?.username?.charAt(0)?.toUpperCase()), 1)
                      ]),
                      _: 1
                    }, 8, ["image"]),
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(userStore).userInfo?.username), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_t_button, {
              key: 1,
              variant: "text",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/login')))
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_t_icon, { name: "user" })
              ]),
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createTextVNode(" 登录 "))
              ]),
              _: 1
            }))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_t_menu_item, {
          value: "item1",
          onClick: goToHome
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_t_icon, { name: "code" })
          ]),
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createTextVNode(" E时代IDE "))
          ]),
          _: 1
        }),
        _createVNode(_component_t_menu_item, {
          value: "item2",
          onClick: openExternalLink
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_t_icon, { name: "history" })
          ]),
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createTextVNode(" 更新日志 "))
          ]),
          _: 1
        }),
        _createVNode(_component_t_menu_item, {
          value: "item4",
          disabled: true
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_t_icon, { name: "tag" })
          ]),
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createTextVNode(" v1.0.1 "))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ]),
      _: 1
    }),
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "icp-info" }, [
      _createElementVNode("a", {
        href: "https://beian.miit.gov.cn/",
        target: "_blank",
        rel: "noopener noreferrer"
      }, " 蜀ICP备2024055741号 ")
    ], -1))
  ], 64))
}
}

})