<template>
  <t-dialog
    :visible="visible"
    header="代码片段管理"
    :width="'80vw'"
    :footer="false"
    @close="handleClose"
    :top="50"
    :style="{ height: '80vh', overflow: 'auto' }"
    :min-height="500"
  >
    <div class="snippets-manager">
      <div class="snippets-header">
        <div class="left">
          <div class="button-group">
            <t-button @click="handleAdd" theme="primary">
              <template #icon>
                <add-icon />
              </template>
              添加片段
            </t-button>
            <t-button
              theme="default"
              variant="outline"
              :loading="isRefreshing"
              @click="handleRefresh"
            >
              <template #icon>
                <refresh-icon />
              </template>
              刷新
            </t-button>
          </div>
        </div>
        <div class="right">
          <t-input
            v-model="searchQuery"
            placeholder="搜索代码片段..."
            @enter="handleSearch"
            clearable
          >
            <template #suffix>
              <search-icon />
            </template>
          </t-input>
        </div>
      </div>

      <div class="snippets-list">
        <t-table
          :data="snippetsStore.snippets"
          :columns="columns"
          :empty="'暂无代码片段'"
          row-key="id"
          size="medium"
          :max-height="'80vh'"
          :loading="snippetsStore.isLoading"
        >
          <template #trigger="{ row }">
            <t-tag theme="primary" variant="light">{{ row.trigger }}</t-tag>
          </template>

          <template #content="{ row }">
            <div class="content-preview">{{ row.content }}</div>
          </template>

          <template #description="{ row }">
            <span>{{ row.description || '-' }}</span>
          </template>

          <template #createdAt="{ row }">
            <span>{{ formatDate(row.createdAt) }}</span>
          </template>

          <template #op="slotProps">
            <t-space>
              <t-button
                theme="default"
                variant="text"
                @click="handlePreview(slotProps.row)"
              >
                预览
              </t-button>
              <t-button
                theme="primary"
                variant="text"
                @click="handleEdit(slotProps.row)"
                :loading="isEditing && currentId === slotProps.row.id"
              >
                编辑
              </t-button>
              <t-button
                theme="danger"
                variant="text"
                @click="handleDelete(slotProps.row)"
                :loading="isDeletingMap[slotProps.row.id]"
              >
                删除
              </t-button>
            </t-space>
          </template>
        </t-table>
      </div>
    </div>

    <t-dialog
      :visible="editDialogVisible"
      :header="isEditing ? '编辑片段' : '添加片段'"
      :width="'50vw'"
      @close="closeEditDialog"
      :confirm-btn="{ content: '保存', theme: 'primary', loading: isSaving }"
      :cancel-btn="{ content: '取消', theme: 'default' }"
      @confirm="handleSave"
    >
      <t-form
        ref="form"
        :data="formData"
        :rules="rules"
        @submit="handleSave"
        :label-width="0"
        :style="{ padding: '0 20px' }"
      >
        <t-form-item label="触发词" name="trigger">
          <div class="form-label">
            触发词
            <span class="required-mark">*</span>
          </div>
          <t-input
            v-model="formData.trigger"
            placeholder="输入触发补全的关键词"
          />
        </t-form-item>
        
        <t-form-item label="补全内容" name="content">
          <div class="form-label">
            补全内容
            <span class="required-mark">*</span>
          </div>
          <t-textarea
            v-model="formData.content"
            placeholder="输入要补全的代码内容"
            :autosize="{ minRows: 5, maxRows: 10 }"
          />
        </t-form-item>
        
        <t-form-item label="描述" name="description">
          <div class="form-label">描述</div>
          <t-input
            v-model="formData.description"
            placeholder="输入片段描述（可选）"
          />
        </t-form-item>
      </t-form>
    </t-dialog>

    <t-dialog
      v-model:visible="previewVisible"
      header="代码片段预览"
      :width="800"
      :footer="false"
    >
      <div class="preview-content">
        <div class="preview-header">
          <div class="preview-info">
            <div class="preview-trigger">
              触发词：<t-tag theme="primary" variant="light">{{ previewData.trigger }}</t-tag>
            </div>
            <p>{{ previewData.description || '暂无描述' }}</p>
          </div>
        </div>
        <div class="preview-code">
          <pre><code :class="getHighlightClass()">{{ previewData.content }}</code></pre>
        </div>
      </div>
    </t-dialog>
  </t-dialog>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, nextTick, defineProps, defineEmits } from 'vue';
import { useSnippetsStore, type CodeSnippet } from '@/store/snippets';
import { AddIcon, SearchIcon, RefreshIcon, CodeIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits(['update:visible']);

const snippetsStore = useSnippetsStore();
const editDialogVisible = ref(false);
const isEditing = ref(false);
const currentId = ref('');
const isSaving = ref(false);
const isDeletingMap = ref<Record<string, boolean>>({});
const searchQuery = ref('');
const isRefreshing = ref(false);
const previewVisible = ref(false);
const previewData = reactive({
  trigger: '',
  content: '',
  description: '',
});

const formData = reactive({
  trigger: '',
  content: '',
  description: ''
});

const rules = {
  trigger: [{ required: true, message: '请输入触发词' }],
  content: [{ required: true, message: '请输入补全内容' }]
};

const columns = [
  { colKey: 'trigger', title: '触发词', width: 100 },
  { colKey: 'content', title: '补全内容', width: 100 },
  { colKey: 'description', title: '描述', width: 100 },
  { colKey: 'createdAt', title: '创建时间', width: 100 },
  { colKey: 'op', title: '操作', width: 120 }
];

onMounted(async () => {
  if (props.visible) {
    try {
      const success = await snippetsStore.initialize();
      if (!success) {
        MessagePlugin.warning('获取在线数据失败，使用本地存储的代码片段');
      }
    } catch (error) {
      console.error('初始化代码片段失败:', error);
      MessagePlugin.error('初始化失败，请重试');
    }
  }
});

const handleClose = () => {
  emit('update:visible', false);
};

const handleAdd = () => {
  isEditing.value = false;
  currentId.value = '';
  formData.trigger = '';
  formData.content = '';
  formData.description = '';
  editDialogVisible.value = true;
};

const handleEdit = (row: CodeSnippet) => {
  isEditing.value = true;
  currentId.value = row.id;
  formData.trigger = row.trigger;
  formData.content = row.content;
  formData.description = row.description || '';
  editDialogVisible.value = true;
};

const handleDelete = async (row: CodeSnippet) => {
  isDeletingMap.value[row.id] = false;

  const dialog = DialogPlugin.confirm({
    header: '确认删除',
    body: '确定要删除这个代码片段吗？',
    confirmBtn: {
      content: '确定',
      theme: 'danger',
    },
    cancelBtn: '取消',
    onConfirm: async () => {
      try {
        isDeletingMap.value[row.id] = true;
        
        const success = await snippetsStore.deleteSnippet(row.id);
        if (success) {
          MessagePlugin.success('删除成功');
          await snippetsStore.initialize();
          dialog.hide();
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('删除失败:', error);
          MessagePlugin.error('删除失败，请重试');
        }
      } finally {
        isDeletingMap.value[row.id] = false;
      }
    }
  });
};

const closeEditDialog = () => {
  editDialogVisible.value = false;
  isEditing.value = false;
  currentId.value = '';
};

const handleSave = async () => {
  if (!formData.trigger || !formData.content) {
    MessagePlugin.warning('请填写必填项');
    return;
  }

  isSaving.value = true;
  try {
    let success;
    if (isEditing.value) {
      success = await snippetsStore.updateSnippet(currentId.value, {
        trigger: formData.trigger,
        content: formData.content,
        description: formData.description
      });
    } else {
      success = await snippetsStore.addSnippet({
        trigger: formData.trigger,
        content: formData.content,
        description: formData.description
      });
    }

    if (success) {
      MessagePlugin.success(isEditing.value ? '更新成功' : '添加成功');
      editDialogVisible.value = false;
      isEditing.value = false;
      currentId.value = '';
      await snippetsStore.initialize();
    }
  } finally {
    isSaving.value = false;
  }
};

const handleSearch = async () => {
  if (searchQuery.value.trim()) {
    const results = await snippetsStore.searchSnippets(searchQuery.value);
    if (results.length > 0) {
      snippetsStore.snippets = results;
      snippetsStore.saveToLocalStorage();
    }
  } else {
    await snippetsStore.initialize();
  }
};

const formatDate = (date?: string) => {
  if (!date) return '-';
  return new Date(date).toLocaleString();
};

const handleRefresh = async () => {
  if (isRefreshing.value) return;
  
  isRefreshing.value = true;
  try {
    const success = await snippetsStore.initialize();
    if (success) {
      MessagePlugin.success('代码片段已从云端同步到本地存储');
    } else {
      MessagePlugin.warning('获取在线数据失败，请检查登录状态，当前仍可使用本地存储的代码片段');
    }
  } catch (error) {
    console.error('刷新代码片段失败:', error);
    MessagePlugin.error('刷新失败，请重试');
  } finally {
    isRefreshing.value = false;
  }
};

const getHighlightClass = () => {
  return 'language-cpp';
};

const handlePreview = (snippet: CodeSnippet) => {
  previewData.trigger = snippet.trigger;
  previewData.content = snippet.content;
  previewData.description = snippet.description || '';
  previewVisible.value = true;
  
  nextTick(() => {
    const codeElement = document.querySelector('.preview-code code') as HTMLElement;
    if (codeElement) {
      codeElement.removeAttribute('data-highlighted');
      codeElement.className = getHighlightClass();
      hljs.highlightElement(codeElement);
    }
  });
};
</script>

<style scoped>
.snippets-manager {
  min-height: 400px;
}

.snippets-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-preview {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: monospace;
}

:deep(.t-form__controls) {
  width: 100%;
}

:deep(.t-textarea) {
  font-family: monospace;
}

.right {
  width: 240px;
}

.button-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.form-label {
  font-size: 14px;
  color: var(--td-text-color-primary);
  margin-bottom: 8px;
  font-weight: 500;
  width: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.required-mark {
  color: var(--td-error-color);
  font-size: 16px;
  line-height: 1;
}

:deep(.t-form__item) {
  padding: 0;
  margin-bottom: 24px;
}

:deep(.t-input),
:deep(.t-textarea) {
  width: 100%;
}

:deep(.t-textarea) {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6;
}

:deep(.t-input__inner) {
  width: 100%;
}

.preview-content {
  padding: 16px;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.preview-info {
  flex: 1;
}

.preview-trigger {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--td-text-color-primary);
  display: flex;
  align-items: center;
}

.preview-info p {
  margin: 0;
  font-size: 14px;
  color: var(--td-text-color-secondary);
}

.preview-code {
  background-color: var(--td-bg-color-container);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.preview-code pre {
  margin: 0;
  padding: 16px;
  max-height: 400px;
  overflow: auto;
  background-color: transparent;
}

.preview-code code {
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-break: break-all;
  background: transparent !important;
  padding: 0 !important;
}

/* 高亮样式覆盖 */
:deep(.hljs) {
  background: transparent !important;
  padding: 0 !important;
}

/* 自定义滚动条样式 */
.preview-code pre::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.preview-code pre::-webkit-scrollbar-track {
  background: var(--td-bg-color-component);
  border-radius: 3px;
}

.preview-code pre::-webkit-scrollbar-thumb {
  background: var(--td-component-stroke);
  border-radius: 3px;
}

.preview-code pre::-webkit-scrollbar-thumb:hover {
  background: var(--td-gray-color-6);
}
</style>