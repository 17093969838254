import { defineStore } from 'pinia';

interface UserInfo {
  id: number;
  username: string;
  avatar: string;
  status?: number;
  createdAt?: string;
}

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: null as UserInfo | null,
    isLoggedIn: false,
  }),

  actions: {
    async fetchUserInfo() {
      try {
        const response = await fetch('/api/users/info', {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
        
        if (response.status === 401) {
          throw new Error('未登录或登录已过期');
        }
        
        if (!response.ok) {
          throw new Error(`请求失败: ${response.status}`);
        }

        const data = await response.json();
        this.userInfo = data;
        this.isLoggedIn = true;
        return data;
      } catch (error) {
        this.userInfo = null;
        this.isLoggedIn = false;
        console.error('获取用户信息失败:', error);
        throw error;
      }
    },

    clearUserInfo() {
      this.userInfo = null;
      this.isLoggedIn = false;
    },

    setUserInfo(user: UserInfo) {
      this.userInfo = user;
      this.isLoggedIn = true;
    }
  },
}); 