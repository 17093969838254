import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "snippets-manager" }
const _hoisted_2 = { class: "snippets-header" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "button-group" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "snippets-list" }
const _hoisted_7 = { class: "content-preview" }
const _hoisted_8 = { class: "preview-content" }
const _hoisted_9 = { class: "preview-header" }
const _hoisted_10 = { class: "preview-info" }
const _hoisted_11 = { class: "preview-trigger" }
const _hoisted_12 = { class: "preview-code" }

import { ref, reactive, onMounted, nextTick } from 'vue';
import { useSnippetsStore, type CodeSnippet } from '@/store/snippets';
import { AddIcon, SearchIcon, RefreshIcon, CodeIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'SnippetsManager',
  props: {
    visible: { type: Boolean }
  },
  emits: ['update:visible'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const snippetsStore = useSnippetsStore();
const editDialogVisible = ref(false);
const isEditing = ref(false);
const currentId = ref('');
const isSaving = ref(false);
const isDeletingMap = ref<Record<string, boolean>>({});
const searchQuery = ref('');
const isRefreshing = ref(false);
const previewVisible = ref(false);
const previewData = reactive({
  trigger: '',
  content: '',
  description: '',
});

const formData = reactive({
  trigger: '',
  content: '',
  description: ''
});

const rules = {
  trigger: [{ required: true, message: '请输入触发词' }],
  content: [{ required: true, message: '请输入补全内容' }]
};

const columns = [
  { colKey: 'trigger', title: '触发词', width: 100 },
  { colKey: 'content', title: '补全内容', width: 100 },
  { colKey: 'description', title: '描述', width: 100 },
  { colKey: 'createdAt', title: '创建时间', width: 100 },
  { colKey: 'op', title: '操作', width: 120 }
];

onMounted(async () => {
  if (props.visible) {
    try {
      const success = await snippetsStore.initialize();
      if (!success) {
        MessagePlugin.warning('获取在线数据失败，使用本地存储的代码片段');
      }
    } catch (error) {
      console.error('初始化代码片段失败:', error);
      MessagePlugin.error('初始化失败，请重试');
    }
  }
});

const handleClose = () => {
  emit('update:visible', false);
};

const handleAdd = () => {
  isEditing.value = false;
  currentId.value = '';
  formData.trigger = '';
  formData.content = '';
  formData.description = '';
  editDialogVisible.value = true;
};

const handleEdit = (row: CodeSnippet) => {
  isEditing.value = true;
  currentId.value = row.id;
  formData.trigger = row.trigger;
  formData.content = row.content;
  formData.description = row.description || '';
  editDialogVisible.value = true;
};

const handleDelete = async (row: CodeSnippet) => {
  isDeletingMap.value[row.id] = false;

  const dialog = DialogPlugin.confirm({
    header: '确认删除',
    body: '确定要删除这个代码片段吗？',
    confirmBtn: {
      content: '确定',
      theme: 'danger',
    },
    cancelBtn: '取消',
    onConfirm: async () => {
      try {
        isDeletingMap.value[row.id] = true;
        
        const success = await snippetsStore.deleteSnippet(row.id);
        if (success) {
          MessagePlugin.success('删除成功');
          await snippetsStore.initialize();
          dialog.hide();
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('删除失败:', error);
          MessagePlugin.error('删除失败，请重试');
        }
      } finally {
        isDeletingMap.value[row.id] = false;
      }
    }
  });
};

const closeEditDialog = () => {
  editDialogVisible.value = false;
  isEditing.value = false;
  currentId.value = '';
};

const handleSave = async () => {
  if (!formData.trigger || !formData.content) {
    MessagePlugin.warning('请填写必填项');
    return;
  }

  isSaving.value = true;
  try {
    let success;
    if (isEditing.value) {
      success = await snippetsStore.updateSnippet(currentId.value, {
        trigger: formData.trigger,
        content: formData.content,
        description: formData.description
      });
    } else {
      success = await snippetsStore.addSnippet({
        trigger: formData.trigger,
        content: formData.content,
        description: formData.description
      });
    }

    if (success) {
      MessagePlugin.success(isEditing.value ? '更新成功' : '添加成功');
      editDialogVisible.value = false;
      isEditing.value = false;
      currentId.value = '';
      await snippetsStore.initialize();
    }
  } finally {
    isSaving.value = false;
  }
};

const handleSearch = async () => {
  if (searchQuery.value.trim()) {
    const results = await snippetsStore.searchSnippets(searchQuery.value);
    if (results.length > 0) {
      snippetsStore.snippets = results;
      snippetsStore.saveToLocalStorage();
    }
  } else {
    await snippetsStore.initialize();
  }
};

const formatDate = (date?: string) => {
  if (!date) return '-';
  return new Date(date).toLocaleString();
};

const handleRefresh = async () => {
  if (isRefreshing.value) return;
  
  isRefreshing.value = true;
  try {
    const success = await snippetsStore.initialize();
    if (success) {
      MessagePlugin.success('代码片段已从云端同步到本地存储');
    } else {
      MessagePlugin.warning('获取在线数据失败，请检查登录状态，当前仍可使用本地存储的代码片段');
    }
  } catch (error) {
    console.error('刷新代码片段失败:', error);
    MessagePlugin.error('刷新失败，请重试');
  } finally {
    isRefreshing.value = false;
  }
};

const getHighlightClass = () => {
  return 'language-cpp';
};

const handlePreview = (snippet: CodeSnippet) => {
  previewData.trigger = snippet.trigger;
  previewData.content = snippet.content;
  previewData.description = snippet.description || '';
  previewVisible.value = true;
  
  nextTick(() => {
    const codeElement = document.querySelector('.preview-code code') as HTMLElement;
    if (codeElement) {
      codeElement.removeAttribute('data-highlighted');
      codeElement.className = getHighlightClass();
      hljs.highlightElement(codeElement);
    }
  });
};

return (_ctx: any,_cache: any) => {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_input = _resolveComponent("t-input")!
  const _component_t_tag = _resolveComponent("t-tag")!
  const _component_t_space = _resolveComponent("t-space")!
  const _component_t_table = _resolveComponent("t-table")!
  const _component_t_form_item = _resolveComponent("t-form-item")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_form = _resolveComponent("t-form")!
  const _component_t_dialog = _resolveComponent("t-dialog")!

  return (_openBlock(), _createBlock(_component_t_dialog, {
    visible: _ctx.visible,
    header: "代码片段管理",
    width: '80vw',
    footer: false,
    onClose: handleClose,
    top: 50,
    style: { height: '80vh', overflow: 'auto' },
    "min-height": 500
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_t_button, {
                onClick: handleAdd,
                theme: "primary"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_unref(AddIcon))
                ]),
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createTextVNode(" 添加片段 "))
                ]),
                _: 1
              }),
              _createVNode(_component_t_button, {
                theme: "default",
                variant: "outline",
                loading: isRefreshing.value,
                onClick: handleRefresh
              }, {
                icon: _withCtx(() => [
                  _createVNode(_unref(RefreshIcon))
                ]),
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createTextVNode(" 刷新 "))
                ]),
                _: 1
              }, 8, ["loading"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_t_input, {
              modelValue: searchQuery.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
              placeholder: "搜索代码片段...",
              onEnter: handleSearch,
              clearable: ""
            }, {
              suffix: _withCtx(() => [
                _createVNode(_unref(SearchIcon))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_t_table, {
            data: _unref(snippetsStore).snippets,
            columns: columns,
            empty: '暂无代码片段',
            "row-key": "id",
            size: "medium",
            "max-height": '80vh',
            loading: _unref(snippetsStore).isLoading
          }, {
            trigger: _withCtx(({ row }) => [
              _createVNode(_component_t_tag, {
                theme: "primary",
                variant: "light"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.trigger), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            content: _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(row.content), 1)
            ]),
            description: _withCtx(({ row }) => [
              _createElementVNode("span", null, _toDisplayString(row.description || '-'), 1)
            ]),
            createdAt: _withCtx(({ row }) => [
              _createElementVNode("span", null, _toDisplayString(formatDate(row.createdAt)), 1)
            ]),
            op: _withCtx((slotProps) => [
              _createVNode(_component_t_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_t_button, {
                    theme: "default",
                    variant: "text",
                    onClick: ($event: any) => (handlePreview(slotProps.row))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" 预览 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_t_button, {
                    theme: "primary",
                    variant: "text",
                    onClick: ($event: any) => (handleEdit(slotProps.row)),
                    loading: isEditing.value && currentId.value === slotProps.row.id
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" 编辑 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick", "loading"]),
                  _createVNode(_component_t_button, {
                    theme: "danger",
                    variant: "text",
                    onClick: ($event: any) => (handleDelete(slotProps.row)),
                    loading: isDeletingMap.value[slotProps.row.id]
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" 删除 ")
                    ])),
                    _: 2
                  }, 1032, ["onClick", "loading"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["data", "loading"])
        ])
      ]),
      _createVNode(_component_t_dialog, {
        visible: editDialogVisible.value,
        header: isEditing.value ? '编辑片段' : '添加片段',
        width: '50vw',
        onClose: closeEditDialog,
        "confirm-btn": { content: '保存', theme: 'primary', loading: isSaving.value },
        "cancel-btn": { content: '取消', theme: 'default' },
        onConfirm: handleSave
      }, {
        default: _withCtx(() => [
          _createVNode(_component_t_form, {
            ref: "form",
            data: formData,
            rules: rules,
            onSubmit: handleSave,
            "label-width": 0,
            style: { padding: '0 20px' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_t_form_item, {
                label: "触发词",
                name: "trigger"
              }, {
                default: _withCtx(() => [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "form-label" }, [
                    _createTextVNode(" 触发词 "),
                    _createElementVNode("span", { class: "required-mark" }, "*")
                  ], -1)),
                  _createVNode(_component_t_input, {
                    modelValue: formData.trigger,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.trigger) = $event)),
                    placeholder: "输入触发补全的关键词"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_t_form_item, {
                label: "补全内容",
                name: "content"
              }, {
                default: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "form-label" }, [
                    _createTextVNode(" 补全内容 "),
                    _createElementVNode("span", { class: "required-mark" }, "*")
                  ], -1)),
                  _createVNode(_component_t_textarea, {
                    modelValue: formData.content,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.content) = $event)),
                    placeholder: "输入要补全的代码内容",
                    autosize: { minRows: 5, maxRows: 10 }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_t_form_item, {
                label: "描述",
                name: "description"
              }, {
                default: _withCtx(() => [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "form-label" }, "描述", -1)),
                  _createVNode(_component_t_input, {
                    modelValue: formData.description,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.description) = $event)),
                    placeholder: "输入片段描述（可选）"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }, 8, ["visible", "header", "confirm-btn"]),
      _createVNode(_component_t_dialog, {
        visible: previewVisible.value,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((previewVisible).value = $event)),
        header: "代码片段预览",
        width: 800,
        footer: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[13] || (_cache[13] = _createTextVNode(" 触发词：")),
                  _createVNode(_component_t_tag, {
                    theme: "primary",
                    variant: "light"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(previewData.trigger), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("p", null, _toDisplayString(previewData.description || '暂无描述'), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("pre", null, [
                _createElementVNode("code", {
                  class: _normalizeClass(getHighlightClass())
                }, _toDisplayString(previewData.content), 3)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})