import { defineStore } from 'pinia';
import { MessagePlugin } from 'tdesign-vue-next';

interface CodeTemplate {
  id: string;
  name: string;
  content: {
    code: string;
    language: string;
  };
  description?: string;
  createdAt: string;
}

export const useTemplatesStore = defineStore('templates', {
  state: () => ({
    templates: [] as CodeTemplate[],
    isLoading: false,
    error: null as string | null,
  }),
  
  actions: {
    async fetchTemplates() {
      this.isLoading = true;
      try {
        const response = await fetch('/api/templates', {
          credentials: 'include'
        });
        if (!response.ok) throw new Error('获取模板失败');
        const result = await response.json();
        if (result.success) {
          this.templates = result.data;
        } else {
          throw new Error(result.message || '获取模板失败');
        }
      } catch (error: any) {
        this.error = error.message;
        MessagePlugin.error('获取模板失败');
      } finally {
        this.isLoading = false;
      }
    },

    async addTemplate(template: { name: string; code: string; language: string; description?: string }) {
      try {
        const response = await fetch('/api/templates', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: template.name,
            content: {
              code: template.code,
              language: template.language
            },
            description: template.description
          }),
        });

        if (!response.ok) throw new Error('创建模板失败');
        
        await this.fetchTemplates(); // 重新获取模板列表
        return true;
      } catch (error: any) {
        MessagePlugin.error(error.message);
        return false;
      }
    },
    
    async removeTemplate(id: string) {
      try {
        const response = await fetch(`/api/templates/${id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) throw new Error('删除模板失败');
        
        await this.fetchTemplates(); // 重新获取模板列表
        return true;
      } catch (error: any) {
        MessagePlugin.error(error.message);
        return false;
      }
    },

    async updateTemplate(id: string, template: Partial<CodeTemplate>) {
      try {
        const response = await fetch(`/api/templates/${id}`, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(template),
        });

        if (!response.ok) throw new Error('更新模板失败');
        
        await this.fetchTemplates(); // 重新获取模板列表
        return true;
      } catch (error: any) {
        MessagePlugin.error(error.message);
        return false;
      }
    }
  }
}); 