import { defineStore } from 'pinia';
import { MessagePlugin } from 'tdesign-vue-next';

export interface CodeSnippet {
  id: string;
  trigger: string;
  content: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface SnippetsState {
  snippets: CodeSnippet[];
  isLoading: boolean;
}

export const useSnippetsStore = defineStore('snippets', {
  state: (): SnippetsState => ({
    snippets: JSON.parse(localStorage.getItem('userSnippets') || '[]'),
    isLoading: false
  }),
  
  actions: {
    async initialize() {
      try {
        await this.fetchSnippets();
        this.saveToLocalStorage();
        return true;
      } catch (error) {
        console.error('初始化代码片段失败:', error);
        this.snippets = JSON.parse(localStorage.getItem('userSnippets') || '[]');
        return false;
      }
    },

    saveToLocalStorage() {
      localStorage.setItem('userSnippets', JSON.stringify(this.snippets));
    },

    async fetchSnippets() {
      this.isLoading = true;
      try {
        const response = await fetch('/api/snippets', {
          credentials: 'include'
        });
        if (!response.ok) throw new Error('获取代码片段失败');
        const data = await response.json();
        this.snippets = data.data;
        this.saveToLocalStorage();
      } catch (error) {
        console.error('获取代码片段失败:', error);
        throw error; // 向上传递错误
      } finally {
        this.isLoading = false;
      }
    },

    async addSnippet(snippet: Omit<CodeSnippet, 'id'>) {
      try {
        const response = await fetch('/api/snippets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify(snippet)
        });

        if (!response.ok) throw new Error('创建代码片段失败，请检查登录状态');
        
        const data = await response.json();
        this.snippets.push(data.data);
        this.saveToLocalStorage();
        return true;
      } catch (error) {
        console.error('创建代码片段失败:', error);
        MessagePlugin.error('创建代码片段失败，请检查登录状态');
        return false;
      }
    },
    
    async updateSnippet(id: string, snippet: Partial<CodeSnippet>) {
      try {
        const response = await fetch(`/api/snippets/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify(snippet)
        });

        if (!response.ok) throw new Error('更新代码片段失败');
        
        const data = await response.json();
        const index = this.snippets.findIndex(s => s.id === id);
        if (index !== -1) {
          this.snippets[index] = data.data;
          this.saveToLocalStorage();
        }
        return true;
      } catch (error) {
        console.error('更新代码片段失败:', error);
        MessagePlugin.error('更新代码片段失败');
        return false;
      }
    },
    
    async deleteSnippet(id: string) {
      try {
        const response = await fetch(`/api/snippets/${id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) throw new Error('删除代码片段失败');
        
        return true;
      } catch (error) {
        console.error('删除代码片段失败:', error);
        MessagePlugin.error('删除代码片段失败');
        return false;
      }
    },

    async searchSnippets(query: string) {
      try {
        const response = await fetch(`/api/snippets/search?q=${encodeURIComponent(query)}`, {
          credentials: 'include'
        });
        
        if (!response.ok) throw new Error('搜索代码片段失败');
        
        const data = await response.json();
        if (query.trim() === '') {
          this.snippets = data.data;
          this.saveToLocalStorage();
        }
        return data.data;
      } catch (error) {
        console.error('搜索代码片段失败:', error);
        MessagePlugin.error('搜索代码片段失败');
        return [];
      }
    }
  }
});
